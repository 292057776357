import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    background: ${({ theme }) => theme.color.background};
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    color: ${({ theme }) => theme.color.textGray};
   // scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.font.base};
    font-size: 16px;
    word-spacing: 1px;
	overflow-wrap: break-word;
}

*[hidden], .hidden {
	display: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
}

*::selection {
  color: ${({ theme }) => theme.textWhite};
  background: ${({ theme }) => theme.primary};
}

h1,
h2,
h3,
h4 {
    font-family: ${({ theme }) => theme.font.heading};
    font-weight: normal;
    line-height: 1.3em;
}

h1 {
	font-size: 3rem;
    line-height: 1.2em;
}

h2 {
	font-size: 2.7rem;
}

h3 {
	font-size: 2rem;
}

h4 {
	font-size: 1.8rem;
}

p, strong, b, blockquote {
    line-height: 1.6em;
}

a {
    color: inherit;
}

ul,
li {
    margin: 0;
    padding: 0;
}

button {
	border: none;
	-webkit-appearance: none;
	font-family: ${({ theme }) => theme.font.base};
	font-weight: normal;
	font-size: 1rem;
}

blockquote {
	font-size: 1.5rem;
	font-weight: lighter;
}

img {
	max-width: 100%;
}

.button {
	border-radius: 5px;
	cursor: pointer;
	display: inline-block;
	padding: 15px 20px 15px 55px;
	position: relative;
	text-decoration: none;
	font-weight: bold;

	&:hover .button__arrow {
		transform: scale(1.1, 1);
	}

	&.is-type {
		&-minimal {
			color:  ${({ theme }) => theme.color.primary};

			&.is-white {
				color:  ${({ theme }) => theme.color.textWhite};
			}
		}

		&-ghost {
			border: 1px solid ${({ theme }) => theme.color.textGray};

			&.is-white {
				border-color: ${({ theme }) => theme.color.textWhite};
			}
		}

		&-full {
			background: ${({ theme }) => theme.color.primary};
			color: ${({ theme }) => theme.color.textWhite};
		}
	}

	&__arrow {
		margin: 0 1rem 0 0;
		position: absolute;
		left: 15px;
		top: 1.05rem;
		width: 27px;
		transition: transform .3s;
		transform-origin: left center;
	}
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.vis-hidden:not(:focus):not(:active) {
    border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

.faded {
    visibility: hidden;
}

.animate {
    @media (prefers-reduced-motion) {
        animation: none;
        opacity: 1;

        > * {
            animation: none !important;  
        }
    }
}

.animate-fade {
    animation: fade-up .5s ease-out 1 forwards;

    &.animate--staggered {
        animation: none;

        > * {
            animation: fade-up .5s ease-out 1 forwards;
        }
    }
}

.animate--staggered {
    > *:nth-child(2) {
        animation-duration: .7s;
    }

    > *:nth-child(3) {
        animation-duration: .9s;
    }

    > *:nth-child(4) {
        animation-duration: 1.1s;
    }

    > *:nth-child(5) {
        animation-duration: 1.3s;
    }

    > *:nth-child(6) {
        animation-duration: 1.5s;
    }
}

@keyframes fade-up {
    0% {
        opacity: 0;
        transform: translate3d(0, 5px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@media only screen and (min-width: ${({ theme }) => theme.breakpoint.medium}) {
	.read-width {
        max-width: ${({ theme }) => theme.readWidth}px;
        margin-left: auto;
        margin-right: auto;
    }

    .max-width {
        max-width: ${({ theme }) => theme.maxWidth}px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (min-width: ${({ theme }) => theme.breakpoint.large}) {
    html {
        font-size: 18px;
    }

	p, blockquote {
    	line-height: 1.7em;
	}
}
`;

export default GlobalStyle;