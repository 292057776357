import React from 'react'
import styled from 'styled-components';

const Divider = ({ isWhite, isSpiral, isLarge }) => (
	<Wrapper isLarge={isLarge} className={`${isWhite && 'is-white'} ${isSpiral && 'is-spiral'}`}>
		{isSpiral ? (
			<svg viewBox="0 0 72 21" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M36.4 12.1c0 3.2 5.1 3.4 5.1 0 0-5.2-10.2-6-10.2 0 0 11.4 16.7 9.7 16.7-.2 0-14-24-15.2-24 .2M1 8.3c3.8-.7 12.2-.7 15 4.5M71 9.3c-3.8-.7-12.2-.7-15 4.5" strokeWidth="2" />
			</svg>
		) : (
				<svg viewBox="0 0 70 9" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M69 4c-9 0-9-3-17.5-3S49 7.5 40 7.5M1 4c9 0 9-3 17.5-3S21 7.5 30 7.5" strokeWidth="2" />
					<circle cx="35" cy="4" r="2" />
				</svg>
			)}
	</Wrapper>
)

export default Divider

const Wrapper = styled.div`
position: relative;
width: 100%;
max-width: ${props => props.isLarge ? '85%' : '75%'};
margin: 0 auto 1rem auto;

@media only screen and (min-width: ${({ theme }) => theme.breakpoint.medium}) {
	max-width: ${props => props.isLarge ? '700px' : '500px'};
}

&.is-white {
	&::before, &::after {
		background: ${({ theme }) => theme.color.textWhite};
	}

	svg {
		path {
			stroke: ${({ theme }) => theme.color.textWhite};
		}

		circle {
			fill: ${({ theme }) => theme.color.textWhite};
		}
	}
}

&.is-spiral {
	&::before, &::after {
		top: 7px;
	}
}

&::before, &::after {
	background: ${({ theme }) => theme.color.textGray};
	content: '';
	position: absolute;
	top: 3px;
	height: 1px;
	opacity: .8;
	width: calc(50% - 45px);
}

&::before {
	left: 0;
}

&::after {
	right: 0;
}

svg {
	display: block;
	margin: 0 auto;
	width: 70px;

	path {
		stroke: ${({ theme }) => theme.color.textGray};
	}

	circle {
		fill: ${({ theme }) => theme.color.textGray};
	}
}
`