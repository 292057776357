import React from 'react'
import styled from 'styled-components';

const Spiral = ({ isWhite, isLight, className }) => (
	<svg className={className} width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<Path isWhite={isWhite} d="M13.4 12.1c0 3.2 5.1 3.4 5.1 0 0-5.2-10.2-6-10.2 0C8.3 23.5 25 21.8 25 12 25-2.1 1-3.3 1 12" strokeWidth={isLight ? 1 : 2} strokeLinecap="round" />
	</svg>
)

export default Spiral

const Path = styled.path`
  stroke: ${({ theme, isWhite }) => isWhite ? theme.color.textWhite : theme.color.textGray};
`;