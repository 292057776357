import React from 'react'
import styled from 'styled-components'

import Divider from '../components/layout/Divider'

const Footer = ({ settings }) => {
	const currentYear = new Date().getFullYear();

	return (
		<Wrapper>
			<div className="max-width">
				<Meta>
					<a href="/impressum">
						<Imprint>Impressum &amp; Datenschutz</Imprint>
					</a>
					<Copyright>&copy; {currentYear} Martina Pfister</Copyright>
				</Meta>
				<Divider isWhite isLarge />
				<Genius href="https://fve.app" rel="nofollow">webdesign by fve</Genius>
			</div>
		</Wrapper>
	)
}

export default Footer

const Wrapper = styled.footer`
  background: linear-gradient(160deg, ${({ theme }) => theme.color.primary} 0%, ${({ theme }) => theme.color.primaryDark} 100%);
  color: ${({ theme }) => theme.color.textWhite};
  padding: 1.5rem ${({ theme }) => theme.borderOffset}px;

@media only screen and (min-width: 769px) {
    padding: ${({ theme }) => theme.borderOffset}px 0;
  }

`

const Meta = styled.div`
display: flex;
justify-content: space-between;
margin: 0 0 10px 0;
font-size: .7em;

@media only screen and (min-width: 769px) {
    font-size: .8em;
  }
`

const Imprint = styled.span`
text-decoration: none;
flex: 1;
`

const Copyright = styled.p`
`

const Genius = styled.a`
display: block;
text-align: center;
font-size: .7em;
text-decoration: none;
width: 100%;

@media only screen and (min-width: 769px) {
    font-size: .8em;
  }
`