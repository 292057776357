import React, { useState } from 'react'
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby'
import { Twirl as Hamburger } from 'hamburger-react'
import { useSpring, animated } from 'react-spring'

import SpiralIcon from '../components/icons/Spiral'

import vars from '../../globalVariables.json'

const Navigation = ({ settings, location }) => {
	const [isOpen, setOpen] = useState(false)

	const animation = useSpring({
		transform: isOpen ? 'scale(1)' : 'scale(1.1)',
		config: {
			mass: 1.5,
			tension: 250
		}
	})

	return (
		<Wrapper ariaLabel="Hauptnavigation">
			<MenuWrapper className={isOpen && 'is-open'}>
				<Menu>
					<List style={animation}>
						{settings.navigation && (settings.navigation
							.sort((a, b) => a.position > b.position)
							.map(navItem => (navItem.slug !== 'settings' && navItem.slug !== 'impressum' && navItem.parent_id === 0) && (
								<Item key={navItem.uuid}>
									<Link
										to={`/${navItem.slug === 'home' ? '' : navItem.slug}`}
										activeClassName="is-current"
										partiallyActive={navItem.slug !== 'home'}>
										{navItem.name}
									</Link>

									<Spiral isWhite isLight />
								</Item>
							)))}
					</List>
				</Menu>
			</MenuWrapper>

			<HamburgerWrapper>
				<Hamburger toggled={isOpen} toggle={setOpen} label="Menu anzeigen"
					color={vars.style.color.textWhite} rounded />
			</HamburgerWrapper>
		</Wrapper>
	)
}

export default Navigation

const Wrapper = styled.nav`
  overflow: hidden;
`;

const MenuWrapper = styled(animated.div)`
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.textWhite};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  transition: opacity .3s, visibility 0s .3s;
  transition-timing-function: ease-in;

  &.is-open {
	  opacity: 1;
	  visibility: visible;

	  transition-delay: 0s;
	  transition-timing-function: ease-out;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoint.large}) {
	  position: static;
	  opacity: 1;
	  visibility: visible;
  }
`;

const Menu = styled.div`
  align-items: center;
    display: flex;
	flex-wrap: wrap;
	position: relative;
   	justify-content: center;
	   height: 100%;

	@media only screen and (min-width: ${({ theme }) => theme.breakpoint.large}) {
	  display: block;
	  margin: 0 0 1rem 0;
    }
`;

const List = styled(animated.ul)`
  @media only screen and (min-width: ${({ theme }) => theme.breakpoint.large}) {
	  transform: none !important;
    }
`;

const Item = styled.li`
  display: block;
  position: relative;
  width: 100%;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoint.large}) {
	  display: inline-block;
	  width: auto;
    }
`;

const Spiral = styled(SpiralIcon)`
position: absolute;
left: -2rem;
top: 1.5rem;
opacity: 0;
transform: scale(.8);
transition: opacity .2s, transform .3s;

@media only screen and (min-width: ${({ theme }) => theme.breakpoint.large}) {
	left: calc(50% - .7rem);
	top: auto;
	bottom: 1px;
	width: 1.3rem;
}
`;

const Link = styled(GatsbyLink)`
  font-size: 2rem;
  line-height: 4rem;
  font-weight: lighter;
  text-decoration: none;
  pointer-events: all;

  &.is-current + ${Spiral} {
	  opacity: 1;
	  transform: scale(1);
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoint.large}) {
	  font-size: 1.2rem;
	  padding: 5px 1rem;

	  &:hover:not(.is-current) + ${Spiral} {
	  opacity: .5;
	  transform: scale(1);
  }
    }
`

const HamburgerWrapper = styled.div`
background: ${({ theme }) => theme.color.primary};
border-radius: 50%;
  padding: 5px;
  position: fixed;
  top: ${({ theme }) => theme.navigationHeight / 2 - 30}px;
  right: 10px;
  z-index: 1;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoint.large}) {
	  display: none;
    }
`;