import React from 'react'
import GatsbyImage from "gatsby-image"
import { getFixedGatsbyImage } from 'gatsby-storyblok-image'

import vars from '../../../globalVariables.json'

const Image = ({ image, sizes, contain, eager, className }) => {
	const src = image.filename
	const alt = image.alt || 'nature'

	if (!src) return <div />

	let sources = [
		getFixedGatsbyImage(src, {
			width: sizes.small.x || 400,
			[sizes.small.y ? 'height' : '_height']: sizes.small.y,
			quality: 90
		})
	]

	if (sizes.medium) {
		sources.push({
			...getFixedGatsbyImage(src, {
				width: sizes.medium.x || 770,
				[sizes.medium.y ? 'height' : '_height']: sizes.medium.y,
				quality: 90
			}),
			media: `(min-width: ${vars.style.breakpoint.medium})`,
		})
	}

	if (sizes.large) {
		sources.push({
			...getFixedGatsbyImage(src, {
				width: sizes.large.x || 1920,
				[sizes.large.y ? 'height' : '_height']: sizes.large.y,
				quality: 100
			}),
			media: `(min-width: ${vars.style.breakpoint.large})`,
		})
	}

	return (
		<figure className={className}>
			<GatsbyImage
				alt={alt}
				fixed={sources}
				loading={eager ? 'eager' : 'lazy'}
				style={contain ? {
					maxWidth: '100%'
				} : {
						height: '100%',
						width: '100%',
					}} />
		</figure>
	)
}

export default Image