import React, { useEffect } from 'react'
import styled from 'styled-components';

import Img from './Image'
import Divider from './Divider'
import HeroVideo from './HeroVideo'


const Hero = ({ title, image, youtubeId, isLarge }) => {
	useEffect(() => {
		document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
	}, [])

	return (
		<>
			<Wrapper className={isLarge ? 'is-large' : ''}>
				{youtubeId ? (
					<HeroVideo youtubeId={youtubeId} />
				) : (
						<Image image={image} sizes={{
							small: {
								y: 500
							},
							medium: {
								y: 500
							},
							large: {
								x: 1280
							}
						}} />
					)}

				<TitleWrapper>
					<div className="read-width">
						<Title>{title}</Title>
					</div>

					<Divider isWhite isSpiral isLarge />

					{isLarge && (
						<Scroller href="#content">
							<Dot />
							<Dot />
							<Dot />
							<span className="sr-only">Scroll to content</span>
						</Scroller>
					)}
				</TitleWrapper>
			</Wrapper>

			<div id="content" />
		</>
	)
}

export default Hero

const Wrapper = styled.div`
  position: relative;
height: 500px;
width: 100%;

@media only screen and (min-height: 900px) {
	height: 600px;
}

&::before {
	background: linear-gradient(160deg, rgba(255, 255, 255, 0) 4.36%, rgba(43, 112, 128, 0.89) 98.3%);
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}

&.is-large {
	height: 100vh;

	@media (pointer: coarse) {
        height: calc(var(--vh, 1vh) * 100);
    }
}
`;

const Image = styled(Img)`
  position: absolute;
height: 100%;
width: 100%;
overflow: hidden;
z-index: -1;
`;

const TitleWrapper = styled.div`
padding: 0 ${({ theme }) => theme.borderOffset}px;
bottom: 2rem;
position: absolute;
margin: 0 auto;
width: 100%;
`

const Title = styled.h1`
color: ${({ theme }) => theme.color.textWhite};
text-align: center;
font-size: 3.8rem;
margin: 0 0 2rem 0;
hyphens: auto;
`

const Scroller = styled.a`
  cursor: pointer;
  display: block;
  padding: 10px 20px;
  position: relative;
  height: 40px;
  margin: 4rem auto 0 auto;
  width: 0px;
`;

const Dot = styled.span`
  background: ${({ theme }) => theme.color.textWhite};
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 16px;
  height: 6px;
  width: 6px;

  &:nth-child(2) {
	  top: 11px;
  }

  &:nth-child(3) {
	  top: 22px;
  }
`;