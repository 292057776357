import React from 'react'
import styled from 'styled-components';

const YoutubeBackground = React.lazy(() => import('react-youtube-background'))

const HeroVideo = ({ youtubeId }) => (
	<Video>
		{typeof window !== "undefined" && (
			<React.Suspense fallback={<div />}>
				<YoutubeBackground videoId={youtubeId} nocookie />
			</React.Suspense>
		)}
	</Video>
)

export default HeroVideo

const Video = styled.div`
position: absolute;
height: 100%;
width: 100%;
overflow: hidden;
z-index: -1;

* {
	height: 100%;
	object-fit: cover;
	width: 100%;
}
`