import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components';

import Header from './Header'
import Footer from './Footer'
import GlobalStyle from "../theme/global-style"
import vars from '../../globalVariables.json'

const Layout = ({ children, settings, location }) => (
	<>
		<Helmet>
			<title>{settings.seo.title}</title>
			<meta property="og:title" content={settings.seo.title} key="title" />

			<meta name="description" content={settings.seo.description} />
			<meta property="og:description" content={settings.seo.description} />

			<meta property="og:image" content={settings?.seoImage?.filename} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:image:alt" content={settings.seo.title} />

			<meta property="og:url" content="https://martinapfister.ch" />
			<meta property="og:site_name" content="Martina Pfister Coaching" />
			<meta property="og:type" content="website" />
			<meta property="og:locale" content="de_CH" />

			<meta name="theme-color" content="#2b7080" />
		</Helmet>
		<ThemeProvider theme={vars.style}>
			<GlobalStyle />

			<Header settings={settings} />

			{children}

			<Footer settings={settings} />
		</ThemeProvider>
	</>
)

export default Layout