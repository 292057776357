import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import Navigation from './Navigation'

import logo from '../images/martina-pfister-web-logo.png';

const Header = ({ settings }) => (
	<Wrapper>
		<Content className="max-width">
			<Link to="/">
				<Logo src={logo} alt="Logo" />
			</Link>

			<Navigation settings={settings} />
		</Content>
	</Wrapper>
)

export default Header

const Wrapper = styled.header`
background: ${({ theme }) => theme.color.primary};
padding: 0 ${({ theme }) => theme.borderOffset}px;
position: absolute;
left: 0;
top: 0;
height: ${({ theme }) => theme.navigationHeight}px;
width: 100%;
z-index: 3;

@media only screen and (min-width: ${({ theme }) => theme.breakpoint.medium}) {
	position: fixed;
}

@media only screen and (min-width: 1025px) {
    padding: 0 ${({ theme }) => theme.borderOffsetLarge}px;
}
`

const Content = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
`;

const Logo = styled.img`
height: 100%;
width: 200px;
`

const Link = styled(GatsbyLink)`
  display: flex;
`;